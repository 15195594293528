<template>
    <b-container fluid>
      <b-row style="border-bottom: 1px solid grey">
        <b-col>
          <b-alert v-if="item.demo" show variant="info">
            <router-link :to="{ name: 'Account', params: { id: item.id }}"><h6>{{item.organisation}} [{{item.id}}]</h6></router-link>
          </b-alert>
          <b-alert v-else show variant="light">
            <router-link :to="{ name: 'Account', params: { id: item.id }}"><h6>{{item.organisation}} [{{item.id}}]</h6></router-link>
          </b-alert>
        </b-col>
        <b-col>
          <div>{{item.comments}}</div>
        </b-col>
        <b-col>
          <div><b-alert show :variant="variant">expiry on {{item.expiry}} in {{duration.humanize()}}</b-alert></div>
        </b-col>
        <b-col cols="1" class="text-center">
          <delete-standard :id="item.id" model="account"></delete-standard>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import DeleteStandard from '@/components/DeleteStandard'

export default {
  name: 'AccountSimple',
  props: ['item', 'username'],
  components: {
    DeleteStandard
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permission.edit = ac.can(this.user.acgroups).updateAny('account').granted
    this.duration = moment.duration(moment(this.item.expiry).diff(moment()))
    const expiry = this.duration.asDays()
    if (expiry < 30) {
      this.variant = 'warning'
    }
    if (expiry < 5) {
      this.variant = 'danger'
    }
  },
  data () {
    return {
      duration: null,
      permission: {
        edit: false
      },
      variant: 'success'
    }
  }
}
</script>

<style>
</style>
